import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from "./i18n";
import { createToaster } from "@meforma/vue-toaster";

const axiosInstance = axios.create({
    baseURL: "https://back-mlm.test-vip.kz/api/",
    headers: {
        "Accept-Language": localStorage.getItem('lang') || "ru"
    },
});
axios.defaults.baseURL = "https://back-mlm.test-vip.kz/api/";
axios.defaults.headers = {
    "Accept-Language": localStorage.getItem('lang') || "ru"
};

const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
app.config.globalProperties.$axios = { ...axiosInstance };
app.config.silent = true
app.config.globalProperties.$toaster = createToaster({ position: "top-right" });
app.mount('#app')
