<template>
  <div class="navigation__desktop">
    <div class="navigation_dashboard-nav blocks" v-if="getAuth">
      <router-link
          to="/personal/user-account"
          class="navigation_dashboard-nav-pages"
          :class="{ 'active': $route.path.startsWith('/personal/user-account') }"
      >
        <div class="navigation_dashboard-nav-pages-username">
          <img src="@/assets/icons/user-profile.svg" alt="accprofile">
          <p class="semi-bold-txt">{{ getUser?.name }}</p>
        </div>
        <div class="navigation_dashboard-nav-pages-logout">
          <img
              style="cursor: pointer;"
              @click="logoutAcc"
              src="@/assets/icons/logout.svg"
              alt="logout"
          >
        </div>
      </router-link>
      <router-link
          to="/personal"
          class="navigation_dashboard-nav-pages"
          :class="{ 'active': $route.path === '/personal' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal' }"
        >
          {{ $t('profile') }}
        </p>
      </router-link>
      <router-link
          to="/personal/wallet-history"
          class="navigation_dashboard-nav-pages"
          :class="{ 'active': $route.path === '/personal/wallet-history' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/wallet-history' }"
        >
          {{ $t('personal.walletHistory') }}
        </p>
      </router-link>
      <router-link
          to="/personal/matching-history"
          class="navigation_dashboard-nav-pages"
          :class="{ 'active': $route.path === '/personal/matching-history' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/matching-history' }"
        >
          {{ $t('personal.matching') }}
        </p>
      </router-link>
      <router-link
          to="/personal/transactions"
          class="navigation_dashboard-nav-pages"
          :class="{ 'active': $route.path === '/personal/transactions' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/transactions' }"
        >
          {{ $t('personal.transaction') }}
        </p>
      </router-link>
      <router-link
          to="/personal/tariffs"
          class="navigation_dashboard-nav-pages"
          :class="{ 'active': $route.path === '/personal/tariffs' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/tariffs' }"
        >
          {{ $t('personal.tariffs') }}
        </p>
      </router-link>
    </div>
  </div>

  <div class="navigation__mobile">
    <div class="navigation__mobile_flex">
      <div @click.prevent="showBurgerMenu" :class="{ 'burger-active': isBurgerActive }" class="navigation__mobile_flex-burger">
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <router-link
          to="/personal/user-account"
          class="navigation__mobile_flex-nav"
          v-if="getAuth"
      >
        <div class="navigation__mobile_flex-nav-username">
          <img src="@/assets/icons/user-profile.svg" alt="accprofile">
          <p class="semi-bold-txt">{{ getUser?.name }}</p>
        </div>
      </router-link>
      <router-link
          to="/"
          class="navigation__mobile_flex-nav"
          v-else
      >
        <div class="navigation__mobile_flex-nav-username">
          <img src="@/assets/icons/user-profile.svg" alt="accprofile">
          <p class="semi-bold-txt">{{ $t('auth.login') }}</p>
        </div>
      </router-link>
    </div>
    <div class="navigation__mobile-routes" :class="{'navigation__mobile-routes-view': isBurgerActive}">
      <router-link
          to="/personal"
          class="navigation__mobile-routes-pages"
          :class="{ 'active': $route.path === '/personal' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal' }"
        >
          {{ $t('profile') }}
        </p>
      </router-link>
      <router-link
          to="/personal/wallet-history"
          class="navigation__mobile-routes-pages"
          :class="{ 'active': $route.path === '/personal/wallet-history' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/wallet-history' }"
        >
          {{ $t('personal.walletHistory') }}
        </p>
      </router-link>
      <router-link
          to="/personal/matching-history"
          class="navigation__mobile-routes-pages"
          :class="{ 'active': $route.path === '/personal/matching-history' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/matching-history' }"
        >
          {{ $t('personal.matching') }}
        </p>
      </router-link>
      <router-link
          to="/personal/transactions"
          class="navigation__mobile-routes-pages"
          :class="{ 'active': $route.path === '/personal/transactions' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/transactions' }"
        >
          {{ $t('personal.transaction') }}
        </p>
      </router-link>
      <router-link
          to="/personal/tariffs"
          class="navigation__mobile-routes-pages"
          :class="{ 'active': $route.path === '/personal/tariffs' }"
      >
        <p
            class="semi-bold-txt"
            :class="{ 'activeBorder': $route.path === '/personal/tariffs' }"
        >
          {{ $t('personal.tariffs') }}
        </p>
      </router-link>
      <div class="navigation__mobile-routes-pages">
        <p
            class="semi-bold-txt"
            style="cursor: pointer;"
            @click="logoutAcc"
        >
          Выйти
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
  },
  data() {
    return {
      isBurgerActive: false,
    };
  },
  computed: {
    ...mapGetters(['getAuth', 'getUser']),
  },
  methods: {
    ...mapActions(["logoutUser", "requestUser"]),
    showBurgerMenu() {
      return this.isBurgerActive = !this.isBurgerActive,
          this.openMarsh = false
    },
    async logoutAcc() {
      await this.logoutUser();
      this.$router.push("/");
    },
  },
  created() {
    this.requestUser();
  }
};
</script>

<style lang="scss" scoped>
.navigation_dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-nav {
    display: flex;
    flex-direction: column;
    padding: 20px 0 400px 0;
    width: 100%;
    gap: 50px;
    @media screen and (max-width: $tablet) {
      display: none;
    }
    &-pages {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
      &-username {
        display: flex;
        align-items: center;
        padding-left: 30px;
        img {
          width: 30px;
          height: 30px;
        }
      }
      &-logout {
        img {
          width: 42px;
          height: 24px;
        }
      }
      p {
        display: flex;
        align-items: center;
        padding-left: 30px;
        font-size: clamp(16px, calc(1rem + ((1vw - 4.24px) * 0.2674)), 20px);
        &.activeBorder {
          border-left: 10px solid $secondColor;
          height: 36px;
          border-radius: 5px;
        }
      }
      &:hover {
        background-color: $mainColor;
      }
      &.active {
        background-color: $mainColor;
      }
    }
  }
}

.navigation__mobile {
  display: none;
  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-burger {
      display: block;
      width: 45px;
      height: 24px;
      position: relative;
      cursor: pointer;
      z-index: 9;

      & span {
        transition: all .3s ease 0s;
        top: calc(50% - 2px);
        left: 0;
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: $secondColor;
        border: 1.7px solid $secondColor;
        border-radius: 25px;

        &:first-child {
          width: 60px;
          top: 0;
        }

        &:last-child {
          top: auto;
          width: 60px;
          bottom: 0;
        }
      }
    }
    &-nav {
      &-username {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  &-routes {
    display: none;
    &-view {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding-top: 40px;
      width: 100%;
    }
  }
}
.burger-active {
  span {
    transform: scale(0);

    &:first-child {
      transform: rotate(-45deg);
      top: calc(50% - 1px);
    }

    &:last-child {
      transform: rotate(45deg);
      bottom: calc(50% - 3px);
    }
  }
}
</style>