<template>
  <div class="matching">
    <div class="matching__content">
      <div
          class="matching__back"
          @click="$router.push('/personal/matching-history')"
      >
        <img src="@/assets/icons/back-btn.svg" alt="back-btn">
        <span class="light-txt">{{ $t('buttons.back') }}</span>
      </div>
      <span>
        <div class="matching__zoom">
          <button class="main-button" @click="zoomIn">{{ $t('buttons.zoomIn') }}</button>
          <button class="main-button" @click="zoomOut">{{ $t('buttons.zoomOut') }}</button>
        </div>
            <vue-tree
                :dataset="[user]"
                :config="treeConfig"
                linkStyle="straight"
                direction="vertical"
                class="matching__content"
                ref="matchingTree"
            >
                <template v-slot:node="{ node }">
                    <BonusCard :card="node"/>
                </template>
            </vue-tree>
        </span>
    </div>
  </div>
</template>
<script>
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

import BonusCard from '@/components/BonusCard.vue'
export default {
  components: {BonusCard, VueTree},
  data() {
    return {
      treeConfig: { nodeWidth: 220, nodeHeight: 100 , levelHeight: 200 },
      user: {},
    };
  },
  methods: {
    convertToTree(children) {
      if (!children || children.length === 0) {
        return null;
      }
      return children.map((child) => ({
        generation: child.generation,
        user: child.name,
        points: child.balance,
        count: child.count,
        total_count: child.total_count,
        children: this.convertToTree(child.children),
      }));
    },
    zoomIn() {
      this.$refs.matchingTree.zoomIn();
    },
    zoomOut() {
      this.$refs.matchingTree.zoomOut();
    },
  },
  computed: {
  },
  async created() {
    await this.$axios.get(`/V1/user/profile/tree`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then((response) => {
          const userData = response.data.user;
          let generationMain = userData.generation;
          if (generationMain === 0) {
            generationMain = this.$t('main');
          } else {
            return generationMain;
          }
          this.user = {
            generation: generationMain,
            user: userData.name,
            points: userData.balance,
            count: userData.count,
            total_count: userData.total_count,
            children: this.convertToTree(userData.children),
          };
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
  }
}
</script>
<style lang="scss" scoped>
.matching {
  display: flex;
  flex-direction: column;
  padding: 150px 50px;
  width: 100%;
  overflow-y: auto;
  @media screen and (max-width: $tablet) {
    padding: 120px 0px;
    overflow-y: hidden;
  }
  &__back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    cursor: pointer;
    padding: 20px 0;
  }
  span {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .matching__zoom {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 30px 0;
      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }
    }
  }
  .tree-container {
    overflow: auto;
  }
  &__content {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__box {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 0px 20px 0px #0000001A;
      &-type {
        padding: 4px 0;
        font-weight: 500;
        font-size: 14px;
        background: $mainColor;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__name {
        margin: 15px 0 20px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        &-p1 {
          font-size: 16px;
          font-weight: 400;
        }
        &-p2 {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #43C667;
  }

  100% {
    background-color: transparent;
  }
}
</style>