<template>
  <div class="transfer__modal-wrapper" v-if="isOpen">
    <div class="transfer__modal-overlay" @click="closeModal"></div>
    <div class="modal">
      <img class="close-modal" :src="require('@/assets/icons/x.svg')" @click="closeModal" alt="user-plus" />
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: ["isOpen"],
  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
  watch: {
    isOpen() {
      this.isOpen === false ? this.trues = true : null
      this.isOpen ? document.documentElement.style.overflowY = 'hidden' : document.documentElement.style.overflowY = 'auto'
    }
  }
};
</script>

<style lang="scss" scoped>
.transfer__modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.transfer__modal-overlay {
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background: white;
  overflow-y: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  animation: modal-appear 0.5s;
  position: relative;
  border-radius: 5px;
  @media screen and (max-width: $mobile) {
    padding: 37px 20px;
    width: 90vw;
    height: auto;
  }
  &-content {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
    &-row {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &-col {
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      input, textarea {
        padding: 12px 15px;
        border: 1px solid #E0E0E0;
        border-radius: 160px;
        outline: none;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
      textarea {
        border-radius: 20px;
        padding: 18px 20px;
        resize: none;
      }
    }
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
  &-desc {
    text-align: center;
    padding: 20px 0 26px 0;
  }
  &-input {
    width: calc(100% - 15px);
    padding: 20px 0 20px 15px;
    border: 1px solid #e0e0e0;
    outline: none;
    border-radius: 290px;
    font-size: 16px;
    margin-bottom: 35px;
  }
  &__buttons {
    margin-top: 18px;
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 30px;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
