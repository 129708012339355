<template>
  <div class="main">
    <Header />
    <div class="main__app">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from './components/layouts/Footer.vue';
import Header from './components/layouts/Header.vue';

export default {
  name: 'App',
  components: {
    Footer,
    Header
  }
}
</script>

<style>
.main {
  background-color: #f8f8f8;
  position: relative;
  z-index: 1;
}
</style>
