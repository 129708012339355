<template>
  <div class="bonus__content__box" :class="{ 'not-active': card.total_count === 0 || card.points <= 0 }">
    <p class="bonus__content__box-type regular-txt">{{ card.generation }}</p>
    <div class="bonus__content__box__name">
      <p class="bonus__content__box__name-p1 regular-txt">{{ $t('mainUser') }}: {{ card.user }}</p>
      <p class="bonus__content__box__name-p1 light-txt">{{ card.points }} {{ $t('personal.points') }}</p>
      <p class="bonus__content__box__name-p1 light-txt">{{ $t('personal.referralCount') }}: {{ card.total_count }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['card'],
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.bonus__content__box {
  user-select: none;
  -webkit-user-drag: none;
  background: white;
  width: fit-content;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px #0000001A;
  margin: 10px;
  width: 200px;
  &.not-active {
    user-select: none;
    -webkit-user-drag: none;
    background: #ea9899;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px #0000001A;
    margin: 10px;
    width: 200px;
  }
  &-type {
    padding: 4px 0;
    font-weight: 500;
    font-size: 14px;
    background: $mainColor;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $tablet) {
      font-size: 14px;
    }
  }
  &__name {
    margin: 15px 0 20px 0;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    &-p1 {
      text-align: center;
      font-size: 16px;
      @media screen and (max-width: $tablet) {
        font-size: 12px;
      }
    }
    &-p2 {
      text-align: center;
      font-size: 14px;
      @media screen and (max-width: $tablet) {
        font-size: 10px;
      }
    }
  }
}

</style>