<template>
  <div class="user container">
    <div class="user__block green-block">
      <div class="user__block-info">
        <div class="user__block-info_content">
          <div class="user__block-info_content-img">
            <img src="@/assets/icons/user-profile.svg" alt="accprofile">
            <p class="semi-bold-txt">{{ getUser?.name }}</p>
          </div>
          <div class="user__block-info_content-routes">
            <button
                class="user__block-info_content-routes-btn white-button"
                @click="$router.push('/personal/user-account')"
                :class="{ 'inactive': $route.path !== '/personal/user-account' }"
            >
              {{ $t('user.userData') }}
            </button>
            <button
                class="user__block-info_content-routes-btn white-button"
                @click="$router.push('/personal/user-account/change')"
                :class="{ 'inactive': $route.path !== '/personal/user-account/change' }"
            >
              {{ $t('user.changeData') }}
            </button>
          </div>
        </div>
      </div>

      <div class="user__block-inputs">
        <PersonalData :user="getUser" v-if="$route.path === '/personal/user-account'" ref="persdata"/>
        <ChangeData :user="getUser" v-else-if="$route.path === '/personal/user-account/change'" ref="changedata"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PersonalData from "@/components/user/PersonalData.vue";
import ChangeData from "@/components/user/ChangeData.vue";
export default {
  components: {
    PersonalData,
    ChangeData
  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
    ...mapGetters(['getAuth', 'getUser']),
  },
}
</script>

<style lang="scss" scoped>
.user {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__block {
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: 100%;
    &-info {
      display: flex;
      align-items: baseline;
      gap: 20px;
      &_content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-img {
          display: flex;
          flex-direction: column;
          gap: 20px;
          img {
            width: 67px;
            height: 67px;
          }
        }
        &-routes {
          display: flex;
          align-items: center;
          gap: 15px;
          button {
            box-shadow: 0px 4px 8px 0px #00000040;
            background-color: #86D4DF !important;
            &.inactive {
              opacity: 0.5;
              background-color: white !important;
            }
          }
        }
      }
    }
  }
}

</style>