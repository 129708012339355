import { createStore } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import axios from "axios";

const toaster = createToaster({ position: "top-right", duration: 1900 });

export default createStore({
    state: {
        lang: localStorage.getItem('lang') || "ru",
        auth: localStorage.getItem("access_token") ? true : false,
        user: {},
    },
    getters: {
        getAuth(state) {
            return state.auth;
        },
        getUser: (state) => state.user,
        getLang: (state) => state.lang,
    },
    mutations: {
        SET_AUTH(state, auth) {
            state.auth = auth;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        UPDATE_LANG(state, lang) {
            state.lang = lang
            localStorage.setItem("lang", lang);
        },
    },
    actions: {
        checkAuth({ commit }) {
            if (localStorage.getItem("access_token")) commit("SET_AUTH", true);
            else commit("SET_AUTH", false);
        },
        async requestUser({commit}) {
            await axios.post(`V1/user/me`, [] , {
                params:{
                    lang: localStorage.getItem('lang') || 'ru'
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }

            })
                .then(response => {
                    commit("SET_USER", response.data.user);
                    commit("SET_AUTH", true);
                })

                .catch(error => {
                    commit("SET_USER", {});
                    commit("SET_AUTH", false);
                    localStorage.removeItem("access_token");
                })
        },
        async logoutUser({ commit }) {
            try {
                const response = await axios.post('V1/user/logout', {}, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                });
                localStorage.removeItem("access_token");
                toaster.success(response.data.message);
                commit("SET_USER", {});
                commit("SET_AUTH", false);
            } catch (err) {
                console.log(err.message);
            }
        },
    },
    modules: {
    }
})
