<template>
  <div class="changedata">
    <div class="changedata__content">
      <div class="changedata__content-input">
        <p class="regular-txt">{{ $t('auth.currentPasswordInput') }}</p>
        <div class="changedata__content-input-container">
          <input
              :type="showPass ? 'password' : 'text'"
              v-model="oldPassword"
              class="input-style"
              @keyup="onlyLatin()"
              :placeholder="$t('placeholder.currentPassword')"
          />
          <span class="eye" @click="showPass = !showPass">
            <img
                :src="
                require(`@/assets/icons/${
                  !showPass ? 'eyeshow' : 'eyehide'
                }.png`)
              "
            />
          </span>
        </div>
      </div>
      <div class="changedata__content-input">
        <p class="regular-txt">{{ $t('auth.newPasswordInput') }}</p>
        <div class="changedata__content-input-container">
          <input
              :type="showNewPass ? 'password' : 'text'"
              v-model="v$.newPassword.$model"
              class="input-style"
              @keyup="onlyLatin()"
              :placeholder="$t('placeholder.newPassword')"
          />
          <span class="eye" @click="showNewPass = !showNewPass">
            <img
                :src="
                require(`@/assets/icons/${
                  !showNewPass ? 'eyeshow' : 'eyehide'
                }.png`)
              "
            />
          </span>
        </div>
        <template v-if="v$.newPassword.$error">
          <p class="errorValid" v-if="v$.newPassword.required.$invalid" :key="index">{{$t('errors.required')}}</p>
          <p class="errorValid" v-if="v$.newPassword.minLength.$invalid" :key="index">{{$t('errors.minLength', {value: 8})}}</p>
          <p class="errorValid" v-if="v$.newPassword.containsUpperCase.$invalid" :key="index">{{$t('errors.containsUpperCase')}}</p>
          <p class="errorValid" v-if="v$.newPassword.containsNumber.$invalid" :key="index">{{$t('errors.containsNumber')}}</p>
          <p class="errorValid" v-if="v$.newPassword.noSpaces.$invalid" :key="index">{{$t('errors.noSpaces')}}</p>
        </template>
      </div>
      <div class="changedata__content-input">
        <p class="regular-txt">{{ $t('auth.repeatPasswordInput') }}</p>
        <div class="changedata__content-input-container">
          <input
              :type="showRepeatPass ? 'password' : 'text'"
              v-model="v$.repeatPassword.$model"
              class="input-style"
              @keyup="onlyLatin()"
              :placeholder="$t('placeholder.repeatPassword')"
          />
          <span class="eye" @click="showRepeatPass = !showRepeatPass">
            <img
                :src="
                require(`@/assets/icons/${
                  !showRepeatPass ? 'eyeshow' : 'eyehide'
                }.png`)
              "
            />
          </span>
        </div>
        <template v-for="(error, index) of v$.repeatPassword.$errors" :key="index">
          <p class="errorValid">{{ error.$message }}</p>
        </template>
      </div>

      <button
          class="changedata__content-btn main-button"
          @click="changePass"
      >
        {{ $t('buttons.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {mapGetters} from "vuex";
import {
  required,
  minLength,
  helpers, sameAs
} from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useVuelidate(),
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      showPass: true,
      showNewPass: true,
      showRepeatPass: true
    };
  },
  validations() {
    return {
      oldPassword: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: minLength(8),
      },
      newPassword: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: minLength(8),
        containsUpperCase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /\d/.test(value);
        },
        noSpaces(value) {
          return !/\s/.test(value);
        },
      },
      repeatPassword: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        sameAs: helpers.withMessage((this.$t('errors.sameAs')), sameAs(this.newPassword)
        ),
      },
    };
  },
  methods: {
    async changePass() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        const data = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.repeatPassword,
        };
        this.$axios.post(`V1/user/password-update`, data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
        )
            .then(res => {
              this.$toaster.success(this.$t('toasters.changePassword'));
              this.resetForm();
            })
            .catch(err => {
              if(err.response.data.errors) {
                for (let field in err.response.data.errors) {
                  this.$toaster.error(err.response.data.errors[field][0]);
                }
              }
              if(err.response.data.error) {
                this.$toaster.error(err.response.data.error.message);
              }
            })
      }
      else {
        this.$toaster.error(this.$t('toasters.fullInput'));
      }
    },
    resetForm() {
      this.oldPassword = "",
      this.newPassword = "",
      this.repeatPassword = "",
      this.v$.$reset()
    },
    onlyLatin() {
      this.oldPassword = this.oldPassword.replace(/[^a-zA-Z0-9\s.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
    },
  },
  computed: {
    ...mapGetters(['getAuth', 'getUser']),
  }

}
</script>

<style lang="scss" scoped>
.changedata__content {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  &-input {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 15px;
    position: relative;
    @media screen and (max-width: $tablet) {
      width: 100%;
      gap: 10px;
    }
    &-container {
      position: relative;
      width: 100%;
      input {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        padding: 15px;
        border: 1px solid #e0e0e0;
      }
      .eye {
        position: absolute;
        right: 5%;
        top: 20%;
        cursor: pointer;
      }
    }
  }
  &-btn {
    width: 50%;
  }
}

.errorValid {
  color: #f72a2a !important;
  font-size: 14px !important;
  padding: 5px 0;
}
</style>