<template>
  <div class="persdata">
    <div class="persdata__content">
      <div class="persdata__content-half">
        <div class="persdata__content-half-input">
          <p class="regular-txt">{{ $t('auth.fullName') }}</p>
          <input
              type="text"
              class="input-style"
              :placeholder="errors.name || $t('placeholder.fullName')"
              v-model="user.name"
              :class="{ 'error': errors.name }"
          />
        </div>
        <div class="persdata__content-half-input">
          <p class="regular-txt">{{ $t('auth.phoneInput') }}</p>
          <input
              type="text"
              class="input-style"
              :placeholder="errors.phone || $t('placeholder.phone')"
              v-model="user.phone"
              v-imask="phoneMask" @input="isNumber"
              @accept="onAccept" @complete="onComplete"
              :class="{ 'error': errors.phone }"
          />
        </div>
        <div class="persdata__content-half-input">
          <p class="regular-txt">E-mail</p>
          <input
              type="text"
              class="input-style"
              :placeholder="errors.email || $t('placeholder.email')"
              v-model="user.email"
              :class="{ 'error': errors.email }"
              disabled
          />
        </div>
      </div>

      <div class="persdata__content-half">
        <div class="persdata__content-half-input">
          <p class="regular-txt">{{ $t('auth.iinInput') }}</p>
          <input
              type="text"
              class="input-style"
              maxlength="12"
              :placeholder="errors.iin || $t('placeholder.iin')"
              v-model="user.iin"
              :class="{ 'error': errors.iin }"
          />
          <template v-for="(error, index) of v$.iin.$errors" :key="index">
            <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
        <div class="persdata__content-half-input">
          <p class="regular-txt">{{ $t('auth.birthdayInput') }}</p>
          <input
              type="date"
              class="input-style"
              :placeholder="$t('placeholder.birthday')"
              v-model="user.birthday"
              :class="{ 'error': errors.birthday }"
          />
        </div>
        <div class="persdata__content-half-input">
          <div class="persdata__content-half-input-required">
            <p class="regular-txt">{{ $t('placeholder.gender') }}</p>
            <form class="persdata__content-half-input-required-gender">
              <div>
                <input type="radio" value="male" id="0" v-model="user.gender" name="male" required>
                <p class="regular-txt">{{ $t('placeholder.genderMale') }}</p>
              </div>
              <div>
                <input type="radio" value="female" id="1" v-model="user.gender" name="female" required>
                <p class="regular-txt">{{ $t('placeholder.genderFemale') }}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button
        class="persdata__btn main-button"
        @click="updateUser"
    >
      {{ $t('buttons.update') }}
    </button>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import useVuelidate from "@vuelidate/core";
import {email, helpers, minLength, required} from "@vuelidate/validators";

export default {
  props: ["user"],
  data() {
    return {
      v$: useVuelidate(),
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
      errors: {
        name: "",
        birthday: "",
        phone: "",
        email: "",
        iin: "",
      }
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.fullName')), minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        email: helpers.withMessage((this.$t('errors.email')), email),
      },
      phone: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.phone')), minLength(18)),
      },
      iin: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.iin')), minLength(12)),
      },
      birthday: {
        required: helpers.withMessage((this.$t('errors.required')), required),
      },
    };
  },
  computed: {
    ...mapGetters(['getAuth', 'getUser']),
  },
  methods: {
    ...mapActions(["requestUser"]),
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail;
      console.log("complete", maskRef.unmaskedValue);
    },
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error(this.$t('toasters.phone'));
        }
      }
    },
    checkForErrors() {
      if (!this.user.name) {
        this.errors.name = this.$t("placeholders.fio");
      } else {
        this.errors.name = "";
      }

      if (!this.user.birthday) {
        this.errors.birthday = this.$t("placeholders.birthday");
      } else {
        this.errors.birthday = "";
      }

      if (!this.user.phone) {
        this.errors.phone = this.$t("errors.phone");
      } else {
        this.errors.phone = "";
      }

      if (!this.user.email) {
        this.errors.email = this.$t("errors.email");
      } else {
        this.errors.email = "";
      }

      if (!this.user.iin) {
        this.errors.iin = this.$t("errors.iin");
      } else {
        this.errors.iin = "";
      }

      const hasErrors = Object.values(this.errors).some((error) => !!error);

      return hasErrors;
    },
    updateUser(){
      const genderValue = this.user.gender === 'male' ? 0 : 1;
      this.checkForErrors();
      this.$axios.post('V1/user/update', {
        phone: this.user.phone,
        name: this.user.name,
        birthday: this.user.birthday,
        iin: this.user.iin.toString(),
        gender: genderValue,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }).
      then(res => {
        this.requestUser();
        this.$toaster.success(this.$t('toasters.successAuth'))
      }).catch(err => {
        if(err.response.data.errors) {
          for (let field in err.response.data.errors) {
            this.$toaster.error(err.response.data.errors[field][0]);
          }
        }
        if(err.response.data.error) {
          this.$toaster.error(err.response.data.error.message);
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.persdata {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__content {
    width: 100%;
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    gap: 60px;
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
    &-half {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
      @media screen and (max-width: $tablet) {
        width: 100%;
      }
      &-input {
        display: flex;
        flex-direction: column;
        gap: 15px;
        input {
          width: 100%;
          height: 50px;
          border-radius: 5px;
          padding: 15px;
          border: 1px solid #e0e0e0;
        }
        &-required {
          display: flex;
          flex-direction: column;
          gap: 10px;
          &-gender {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            div {
              display: flex;
              align-items: center;
              gap: 10px;
            }
            input[type="radio"] {
              width: 12px;
              height: 12px;
              border: 2px solid white;
              border-radius: 50%;
              outline: none;
              transition: border-color 0.2s ease;
            }
          }
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
}
input[type="date"] .error {
  background-color: #ea9899 !important;
}
.error {
  border: 1px solid #ea9899 !important;
  background-color: #ea9899 !important;
}
.error::placeholder {
  color: black !important;
}
.error:optional {
  color: black !important;
}
</style>