<template>
  <div class="tariffs">
    <div class="tariffs__info container">
      <h1 class="tariffs__info-title semi-bold-txt">{{ $t('personal.tariffs') }}</h1>
      <div
          class="tariffs__info-block"
          v-for="item in tariffs"
          :key="item.id"
      >
        <p class="tariffs__info-block-title semi-bold-txt">{{ item.title }}</p>
        <div class="tariffs__info-block-desc">
          <p class="tariffs__info-block-desc-txt light-txt">{{ item.description }}</p>
          <p class="tariffs__info-block-desc-price regular-txt">{{ item.price }} ₸</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      tariffs: [],
    };
  },
  created() {
    this.fetchTariffs();
  },
  methods: {
    async fetchTariffs() {
      try {
        const response = await this.$axios.get(`/V1/tariffs`, {
          params: {
            lang: localStorage.getItem('lang') || 'ru'
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (response.data && response.data.data) {
          this.tariffs = response.data.data;
        }
      } catch (e) {
        this.$toaster.error(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  color: $blackText;
  margin-top: 150px;
  @media screen and (max-width: $tablet) {
    margin-top: 30px;
  }
  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: 0px 4px 8px 0px #00000040;
    border: 2px solid $secondColor;
    gap: 10px;
    &-desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      p {
        text-align: center;
      }
      &-price {
        font-size: 20px;
      }
    }
  }
}
</style>