<template>
  <div class="footer container">
    <div class="footer__content">
      <div class="footer__content-logo">
        <img :src="logo" alt="logo">
      </div>
      <div
          class="footer__content-routes"
          :class="{ 'not-view': !isAuthenticated }"
      >
        <router-link
            v-if="isAuthenticated"
            to="/personal"
            class="footer__content-routes-nav"
        >
          <p class="regular-txt">{{ $t('profile') }}</p>
        </router-link>
        <router-link
            v-if="isAuthenticated"
            to="/personal/wallet-history"
            class="footer__content-routes-nav"
        >
          <p class="regular-txt">{{ $t('personal.walletHistory') }}</p>
        </router-link>
        <router-link
            v-if="isAuthenticated"
            to="/personal/matching-history"
            class="footer__content-routes-nav"
        >
          <p class="regular-txt">{{ $t('personal.matching') }}</p>
        </router-link>
        <router-link
            v-if="isAuthenticated"
            to="/personal/transactions"
            class="navigation_dashboard-nav-pages"
        >
          <p class="regular-txt">{{ $t('personal.transaction') }}</p>
        </router-link>
        <router-link
            v-if="isAuthenticated"
            to="/personal/tariffs"
            class="navigation_dashboard-nav-pages"
        >
          <p class="regular-txt">{{ $t('personal.tariffs') }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name:'FooterVue',
  data() {
    return {
      logo: '',
    };
  },
  async created() {
    await this.$axios.get(`/V1/logo`,
    )
        .then((response) => {
          if (response.data.logo) {
            this.logo = response.data.logo;
          }
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
  },
  computed: {
    ...mapGetters(['getAuth', 'getUser']),
    isAuthenticated() {
      return this.getAuth;
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: $whiteText;
  background-color: #000000;
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 50px;
    @media screen and (max-width: $tablet) {
      padding: 40px 20px;
    }
    &-logo {
      display: flex;
      flex-direction: column;
      img {
        width: 100px;
        height: 100px;
      }
    }
    &-routes {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 25px 0;
      gap: 40px;
      &-nav {
        .static-link {
          cursor: not-allowed;
        }
      }
    }
  }
}
.not-view {
  display: none;
}
</style>
