import { createRouter, createWebHistory } from 'vue-router'
import SuccessfullyPage from '@/views/SuccessfullyPage.vue'
import NotFound from '@/views/NotFoundPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import RegistrationPage from '@/views/RegistrationPage.vue'
import PersonalAccount from "@/views/PersonalAccount.vue";
import WalletHistoryPage from "@/views/WalletHistoryPage.vue";
import MatchingPage from "@/views/MatchingPage.vue";
import UserAccount from "@/views/UserAccount.vue";
import ChangeData from "@/components/user/ChangeData.vue";
import MatchingHistory from "@/views/MatchingHistory.vue";
import TransactionsPage from "@/views/TransactionsPage.vue";
import TariffsPage from "@/views/TariffsPage.vue";

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/registration',
        name: 'registration',
        component: RegistrationPage
    },
    {
        path: '/personal',
        name: 'personal',
        component: PersonalAccount,
        children: [
            {
                path: '/personal/user-account',
                name: 'user-account',
                component: UserAccount,
                children: [
                    {
                        path: '/personal/user-account/change',
                        name: 'change',
                        component: ChangeData
                    }
                ]
            },
            {
                path: '/personal/wallet-history',
                name: 'wallet-history',
                component: WalletHistoryPage,
            },
            {
                path: '/personal/matching-history',
                name: 'matching-history',
                component: MatchingHistory,
            },
            {
                path: '/personal/matching-history/tree',
                name: 'tree',
                component: MatchingPage
            },
            {
                path: '/personal/transactions',
                name: 'transactions',
                component: TransactionsPage,
            },
            {
                path: '/personal/tariffs',
                name: 'tariffs',
                component: TariffsPage,
            },
        ],
    },
    {
        path: '/successfully',
        name: 'successfully',
        component: SuccessfullyPage
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
})

export default router
