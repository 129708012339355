import { createI18n } from "vue-i18n";
import store  from "./store";

export default createI18n({
    locale: `${store.getters.getLang}`,
    fallbackLocale: "ru",
    messages: {
        ru: require("./locales/ru.json"),
        kz: require("./locales/kz.json"),
    },
});