<template>
  <div class="header"
       v-if="$route.path.startsWith('/personal')"
  >
    <div class="header__content container">
      <div class="header__content-withdrawal">
        <button
            class="main-button"
            @click="isOpen = true"
            v-if="$route.path.startsWith('/personal/matching-history')"
        >
          {{ $t('buttons.withdrawalBalance') }}
        </button>
      </div>
      <div class="header__content-info">
        <p class="regular-txt" v-if="getUser?.tariff ">{{ $t('personal.userTariff') }}: <span>{{ getUser?.tariff }}</span></p>
        <p class="regular-txt" v-if="getUser?.tariff_end_date ">{{ $t('personal.userEndDate') }}: <span>{{ getUser?.tariff_end_date }}</span></p>
        <p class="regular-txt">{{ $t('personal.userInvited') }}: <span>{{ getUser?.phone_invite }}</span></p>
      </div>
    </div>
  </div>
  <WithdrawalBalanceModal :is-open="isOpen" @close="isOpen = false">
    <div class="withdrawal">
      <h1 class="withdrawal__title semi-bold-txt">{{ $t('personal.withdrawalMatchingBalance') }}</h1>
      <template v-if="step === 1">
        <div class="withdrawal__inputs">
          <div class="withdrawal__inputs-flex">
            <input
                type="number"
                v-model="w_sum"
                :placeholder="$t('placeholder.sum')"
            />
          </div>
        </div>
        <button @click="wihdrawalSum" class="main-button">{{ $t('buttons.withdrawal') }}</button>
      </template>
      <template v-if="step === 2">
        <div class="withdrawal__successful">
          <div class="withdrawal__successful_view white-block container">
            <h1 class="withdrawal__successful_view-message semi-bold-txt">{{ $t('toasters.successTransfer') }}</h1>
            <div class="withdrawal__successful_view-img">
              <img src="@/assets/images/png/success.png" alt="success">
            </div>
            <buttton @click="$router.push('/personal')" class="withdrawal__successful_view-btn main-button">{{ $t('buttons.onMainPage') }}</buttton>
          </div>
        </div>
      </template>
    </div>
  </WithdrawalBalanceModal>
</template>

<script>
import {mapGetters} from "vuex";
import WithdrawalBalanceModal from "@/components/modal/TransferBalanceModal.vue";

export default {
  name: 'HeaderVue',
  components: {WithdrawalBalanceModal},
  data() {
    return {
      isOpen: false,
      step: 1,
      w_sum: "",
    };
  },
  methods: {
    wihdrawalSum() {
      this.$axios.post('V1/user/profile/output-balances', {
            sum: this.w_sum
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
      ).then(res => {
        if (res.status === 201) {
          this.$toaster.success(res.data.message);
          this.step = 2
        } else {
          this.$toaster.error(res.data.message);
        }
      }).catch(err => {
        this.$toaster.error(err.response.data.message)
      })
    },
  },
  computed: {
    ...mapGetters(['getAuth', 'getUser']),
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: end;
  &__content {
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    width: 100%;
    gap: 20px;
    top: 0;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-end;
    }
    @media screen and (max-width: $tablet) {
      position: relative;
      padding: 20px;
      align-items: center;
      justify-content: center;
    }
    &-withdrawal {
      z-index: 100;
    }
    &-info {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 15px 60px;
      border-radius: 5px;
      gap: 20px;
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
      p {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 10px;
      }
      span {
        color: #000000;
      }
    }
  }
}
.withdrawal {
  width: 100%;
  padding: 37px 40px;
  max-height: 150vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  &__title {
    text-align: center;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
    gap: 15px;
    &-flex {
      input {
        width: 100%;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 20px 15px;
      }
    }
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    color: $whiteText;
    p {
      cursor: pointer;
    }
  }
  &__regis {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    label,
    span,
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: #ffffff;
    }
    &-check {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    p {
      font-size: 18px;
    }
    &-social {
      display: flex;
      align-items: center;
      gap: 25px;
      &-item {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 18px;
        }
        img {
          width: 24px;
          height: 24px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}
.withdrawal__successful {
  width: 100%;
  padding: 37px 40px;
  max-height: 150vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  &_view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;
    padding: 60px;
    height: 100vh;
    &-img {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &-message {
      text-align: center;
      font-size: 30px;
    }
  }
}
</style>
