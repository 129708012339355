<template>
  <div class="transaction">
    <div class="transaction__desktop">
      <div class="transaction__desktop-transfers">
        <h1 class="transaction__desktop-transfers-title semi-bold-txt">{{ $t('personal.tariffs') }}</h1>
        <p class="transaction__desktop-transfers-subtitle regular-txt">{{ $t('history.transferHistory') }}</p>
        <table class="transaction__desktop-transfers-history">
          <thead>
          <tr>
            <th>{{ $t('date') }}</th>
            <th>{{ $t('table.transferAmount') }}</th>
            <th>{{ $t('table.recipientNumber') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(transfer, index) in transfers" :key="index">
            <td class="medium-txt">{{ transfer.date }}</td>
            <td class="sum medium-txt">{{ transfer.sum}} тг</td>
            <td class="medium-txt">{{ transfer.phone }}</td>
          </tr>
          </tbody>
        </table>
        <div class="transaction__desktop-transfers-controls">
          <button
              @click="prevPage"
              class="main-button"
          >
            {{ $t('buttons.back') }}
          </button>
          <span class="medium-txt">{{ currentTransferPage }}</span>
          <button
              @click="nextPage"
              class="main-button"
              :disabled="!hasNextPage"
          >
            {{ $t('buttons.next') }}
          </button>
        </div>
      </div>
    </div>

    <div class="transaction__mobile">
      <div
          class="transaction__mobile-table"
          v-for="(transfer, index) in transfers"
          :key="index"
      >
        <p class="medium-txt">{{ transfer.date }}</p>
        <div class="transaction__mobile-table-transactions green-block">
          <p class="medium-txt">{{ $t('table.recipientNumber') }}: {{ transfer.phone }}</p>
          <div class="transaction__mobile-table-transactions-amount">
            <p class="semi-bold-txt">{{ $t('table.transferAmount') }}: {{ transfer.sum }} тг</p>
          </div>
        </div>
      </div>
      <div class="transaction__mobile-controls">
        <button
            @click="prevPage"
            class="main-button"
        >
          {{ $t('buttons.back') }}
        </button>
        <span class="medium-txt">{{ currentTransferPage }}</span>
        <button
            @click="nextPage"
            class="main-button"
            :disabled="!hasNextPage"
        >
          {{ $t('buttons.next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      transfers: [],
      currentTransferPage: 1,
      transferPageSize: 10,
    };
  },
  async created() {
    await this.fetchTransfers();
    this.loadPageFromLocalStorage();
  },
  beforeRouteLeave(to, from, next) {
    if (this.currentTransferPage) {
      localStorage.removeItem('currentTransferPage');
      next();
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.transfers.length / this.currentTransferPage);
    },
    hasNextPage() {
      return this.currentTransferPage < this.totalPages;
    },
  },
  methods: {
    async fetchTransfers() {
      try {
        const response = await this.$axios.get(`/V1/user/profile/transfer-balances-history`, {
          params: {
            page: localStorage.getItem('currentTransferPage') || 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (response.data && response.data.data) {
          this.transfers = response.data.data;
        }
      } catch (e) {
        this.$toaster.error(e.message);
      }
    },
    nextPage() {
      if (this.currentTransferPage < this.totalPages) {
        this.currentTransferPage += 1;
        this.savePageToLocalStorage();
        this.fetchTransfers();
      }
    },
    prevPage() {
      if (this.currentTransferPage > 1) {
        this.currentTransferPage -= 1;
        this.savePageToLocalStorage();
        this.fetchTransfers();
      }
    },
    savePageToLocalStorage() {
      localStorage.setItem('currentTransferPage', this.currentTransferPage);
    },
    loadPageFromLocalStorage() {
      const storedPage = localStorage.getItem('currentTransferPage');
      this.currentTransferPage = storedPage ? parseInt(storedPage) : 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  color: $blackText;
  margin-top: 150px;
  @media screen and (max-width: $tablet) {
    margin-top: 30px;
  }
  &__desktop {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $tablet) {
      display: none;
    }
    &-withdrawal {
      display: flex;
      justify-content: center;
    }
    &-transfers {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
      gap: 20px;
      &-subtitle {
        color: #8C8C8C;
      }
      &-history {
        color: #8C8C8C;
        width: 100%;
        border-collapse: collapse;
        thead {
          width: 100%;
          th {
            padding: 25px 30px;
            text-align: start;
          }
        }
        tbody {
          width: 100%;
          td {
            text-align: start;
            padding: 20px 30px;
            border-radius: 5px;
          }
          tr:nth-of-type(odd) {
            background-color: rgba(0,0,0,.05);
          }
          tr:nth-of-type(even) {
            background-color: white;
          }
          .sum {
            color: #009F65;
          }
        }
      }
      &-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 15px;
        span {
          padding: 16px;
          border-radius: 5px;
          background-color: #EDEDED;
        }
      }
    }
  }
  &__mobile {
    display: none;
    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      width: 100%;
      gap: 20px;
    }
    &-table {
      &-transactions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-amount {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          p {
            font-size: 24px;
          }
          &-bonus {
            display: flex;
            align-items: center;
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
    &-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 15px;
      span {
        padding: 16px;
        border-radius: 5px;
        background-color: #EDEDED;
      }
    }
  }
}

</style>