<template>
  <div class="matchHistory container">
    <div class="matchHistory__desktop">
      <div class="matchHistory__desktop-head">
        <h1 class="semi-bold-txt">{{ $t('history.replenishmentHistory') }}</h1>
        <button
            class="main-button"
            @click="isOpen = true"
        >
          {{ $t('buttons.transfer') }}
        </button>
        <button
            class="main-button"
            @click="$router.push('/personal/matching-history/tree')"
        >
          {{ $t('buttons.viewBranch') }}
        </button>
      </div>
      <div class="matchHistory__desktop-table green-block">
        <table class="matchHistory__desktop-table-history">
          <thead>
          <tr>
            <th>{{ $t('table.fromName') }}</th>
            <th>№</th>
            <th>{{ $t('table.sum') }}</th>
            <th>{{ $t('bonus') }}</th>
            <th>{{ $t('date') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in history" :key="index">
            <td class="medium-txt">{{ value.from_user }}</td>
            <td class="medium-txt">{{ value.id }}</td>
            <td class="medium-txt">{{ value.amount }} тг</td>
            <td class="medium-txt">{{ value.percent }} %</td>
            <td class="medium-txt">{{ value.date }}</td>
          </tr>
          </tbody>
        </table>
        <div class="matchHistory__desktop-table-controls">
          <button
              @click="prevPage"
              class="main-button"
          >
            {{ $t('buttons.back') }}
          </button>
          <span class="medium-txt">{{ currentHistoryPage }}</span>
          <button
              @click="nextPage"
              class="main-button"
              :disabled="!hasNextPage"
          >
            {{ $t('buttons.next') }}
          </button>
        </div>
      </div>
    </div>

    <div
        class="matchHistory__mobile"
    >
      <button
          class="main-button"
          @click="$router.push('/personal/matching-history/tree')"
      >
        {{ $t('buttons.viewBranch') }}
      </button>
      <button
          class="main-button"
          @click="isOpen = true"
      >
        {{ $t('buttons.transfer') }}
      </button>
      <div
          class="matchHistory__mobile-table"
          v-for="(value, index) in history"
          :key="index"
      >
        <p class="medium-txt">{{ value.date }}</p>
        <p class="medium-txt">№ {{ value.id }}</p>
        <div class="matchHistory__mobile-table-transactions green-block">
          <p class="medium-txt">{{ $t('table.fromName') }}: {{ value.from_user }}</p>
          <div class="matchHistory__mobile-table-transactions-amount">
            <p class="semi-bold-txt">{{ value.amount }} тг</p>
            <div class="matchHistory__mobile-table-amount-bonus">
              <p class="regular-txt">{{ $t('bonus') }}: {{ value.percent }} %</p>
            </div>
          </div>
        </div>
      </div>
      <div class="matchHistory__mobile-controls">
        <button
            @click="prevPage"
            class="main-button"
        >
          {{ $t('buttons.back') }}
        </button>
        <span class="medium-txt">{{ currentHistoryPage }}</span>
        <button
            @click="nextPage"
            class="main-button"
            :disabled="!hasNextPage"
        >
          {{ $t('buttons.next') }}
        </button>
      </div>
    </div>
  </div>
  <TransferBalanceModal :is-open="isOpen" @close="isOpen = false">
    <div class="transfer">
      <h1 class="transfer__title semi-bold-txt">{{ $t('personal.transferToUser') }}</h1>
      <template v-if="step === 1">
        <div class="transfer__inputs">
          <div class="transfer__inputs-flex">
            <input
                type="text"
                v-model="v$.tr_phone.$model"
                :placeholder="$t('placeholder.phone')"
                v-imask="phoneMask"
                @input="isNumber"
                @accept="onAccept"
                @complete="onComplete"
            />
            <template v-for="(error, index) of v$.tr_phone.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
          <div class="transfer__inputs-flex">
            <input
                type="number"
                v-model="v$.tr_sum.$model"
                :placeholder="$t('placeholder.sum')"
            />
            <template v-for="(error, index) of v$.tr_sum.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
        </div>
        <button @click="sendSum" class="main-button">{{ $t('buttons.transfer') }}</button>
      </template>
      <template v-if="step === 2">
        <div class="transfer">
          <h1>{{ $t('auth.pass') }}</h1>
          <div class="transfer__inputs">
            <div class="transfer__inputs-flex">
              <input
                  type="password"
                  v-model="v$.password.$model"
                  :placeholder="$t('placeholder.password')"
                  @keyup="onlyLatin()"
              />
              <template v-for="(error, index) of v$.password.$errors" :key="index">
                <p class="errorValid">{{ error.$message }}</p>
              </template>
            </div>
          </div>
          <label class="code-sec">{{ timeLeft > 0 ? $t('auth.getsms', { value: timeLeft }) : $t('auth.resendPassMessage') }}</label>
          <button v-if="timeLeft === 0" class="main-button" @click="resendPass">{{ $t('buttons.resendPass') }}</button>
          <button class="main-button" @click="checkPass">{{ $t('buttons.apply') }}</button>
        </div>
      </template>
      <template v-if="step === 3">
        <div class="transfer__successful">
          <div class="transfer__successful_view white-block container">
            <h1 class="transfer__successful_view-message semi-bold-txt">{{ $t('toasters.successTransfer') }}</h1>
            <div class="transfer__successful_view-img">
              <img src="@/assets/images/png/success.png" alt="success">
            </div>
            <buttton @click="$router.push('/personal')" class="transfer__successful_view-btn main-button">{{ $t('buttons.onMainPage') }}</buttton>
          </div>
        </div>
      </template>
      <template v-if="step === 4">
        <div class="transfer__denied">
          <div class="transfer__denied_view white-block container">
            <h1 class="transfer__denied_view-message semi-bold-txt">{{ $t('toasters.errorTransfer') }}</h1>
            <div class="transfer__denied_view-img">
              <img src="@/assets/images/png/notfound.png" alt="notfound">
            </div>
            <buttton @click="$router.push('/personal')" class="transfer__denied_view-btn main-button">{{ $t('buttons.onMainPage') }}</buttton>
          </div>
        </div>
      </template>
    </div>
  </TransferBalanceModal>
</template>

<script>
import TransferBalanceModal from "@/components/modal/TransferBalanceModal.vue";
import {IMaskDirective} from "vue-imask";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
export default {
  components: {
    TransferBalanceModal
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      v$: useVuelidate(),
      history: [],
      currentHistoryPage: 1,
      totalPageSize: 10,
      isOpen: false,
      step: 1,
      password: "",
      tr_phone: "",
      tr_sum: "",
      phoneMask: {
        mask: "+{7}-({000})-000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
      timeLeft: 59,
      timer: null,
    };
  },
  validations() {
    return {
      tr_phone: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.phone')), minLength(18)),
      },
      tr_sum: {
        required: helpers.withMessage((this.$t('errors.required')), required),
      },
      password: {
        required: required,
        minLength: minLength(8),
        containsUpperCase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /\d/.test(value);
        },
        noSpaces(value) {
          return !/\s/.test(value);
        },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.currentHistoryPage) {
      localStorage.removeItem('currentHistoryPage');
      next();
    }
  },
  async created() {
    await this.fetchMatchingHistory();
    this.loadHistoryPageFromLocalStorage();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.history.length / this.currentHistoryPage);
    },
    hasNextPage() {
      return this.currentHistoryPage < this.totalPages;
    },
  },
  methods: {
    async fetchMatchingHistory() {
      try {
        const response = await this.$axios.get(`/V1/user/profile/matching-bonuses`, {
          params: {
            page: localStorage.getItem('currentHistoryPage') || 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (response.data && response.data.data) {
          this.history = response.data.data;
        }
      } catch (e) {
        this.$toaster.error(e.message);
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    sendSum() {
      this.$axios.post('V1/user/profile/confirm-transfer-balances', {
        phone: this.tr_phone,
        sum: this.tr_sum
      },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
      ).then(res => {
        this.$toaster.success(res.data.message);
        this.step = 2;
        this.startTimer();
      }).catch(err => {
        this.$toaster.error(err.response.data.message)
      })
    },
    resendPass() {
      this.timeLeft = 59;
      this.sendSum();
    },
    checkPass() {
      this.$axios.post('V1/user/profile/transfer-balances', {
        password: this.password,
      },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
      ).then(res => {
        this.$toaster.success(res.data.message)
        if (res.status === 201) {
          this.step = 3
        } else {
          this.step = 4
        }
      }).catch(err => {
        this.$toaster.error(err.data.message)
      })
    },
    nextPage() {
      if (this.currentHistoryPage < this.totalPages) {
        this.currentHistoryPage += 1;
        this.saveHistoryPageToLocalStorage();
        this.fetchMatchingHistory();
      }
    },
    prevPage() {
      if (this.currentHistoryPage > 1) {
        this.currentHistoryPage -= 1;
        this.saveHistoryPageToLocalStorage();
        this.fetchMatchingHistory();
      }
    },
    saveHistoryPageToLocalStorage() {
      localStorage.setItem('currentHistoryPage', this.currentHistoryPage);
    },
    loadHistoryPageFromLocalStorage() {
      const storedPage = localStorage.getItem('currentHistoryPage');
      this.currentHistoryPage = storedPage ? parseInt(storedPage) : 1;
    },
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error(this.$t('toasters.phone'));
        }
      }
    },
    onlyLatin() {
      this.password = this.password.replace(/[^a-zA-Z0-9\s.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail;
    },
  },
};
</script>

<style lang="scss" scoped>
.matchHistory {
  display: flex;
  flex-direction: column;
  color: $blackText;
  margin-top: 170px;
  width: 100%;
  @media screen and (max-width: $tablet) {
    margin-top: 30px;
  }
  &__desktop {
    display: flex;
    flex-direction: column;
    gap: 25px;
    @media screen and (max-width: $tablet) {
      display: none;
    }
    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      button {
        width: 300px;
      }
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    }
    &-table {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;
      &-history {
        color: #8C8C8C;
        width: 100%;
        border-spacing: 50px 25px;
        thead {
          width: 100%;
          th {
            padding-bottom: 25px;
            text-align: start;
          }
        }
      }
      &-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 15px;
        span {
          padding: 16px;
          border-radius: 5px;
          background-color: #EDEDED;
        }
      }
    }
  }
  &__mobile {
    display: none;
    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      width: 100%;
      gap: 20px;
    }
    &-table {
      display: flex;
      flex-direction: column;
      &-transactions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-amount {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          p {
            font-size: 24px;
          }
          &-bonus {
            display: flex;
            align-items: center;
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
    &-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 15px;
      span {
        padding: 16px;
        border-radius: 5px;
        background-color: #EDEDED;
      }
    }
  }
}
.transfer {
  width: 100%;
  padding: 37px 40px;
  max-height: 150vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  &__title {
    text-align: center;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
    gap: 15px;
    &-flex {
      input {
        width: 100%;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 20px 15px;
      }
    }
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    color: $whiteText;
    p {
      cursor: pointer;
    }
  }
  &__regis {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    label,
    span,
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: #ffffff;
    }
    &-check {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    p {
      font-size: 18px;
    }
    &-social {
      display: flex;
      align-items: center;
      gap: 25px;
      &-item {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 18px;
        }
        img {
          width: 24px;
          height: 24px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}
.transfer__successful {
  &_view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 60px;
    height: 100vh;
    &-img {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &-message {
      text-align: center;
      font-size: 30px;
    }
  }
}
.transfer__denied{
  &_view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 60px;
    height: 100vh;
    &-img {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &-message {
      text-align: center;
      font-size: 30px;
    }
  }
}
.errorValid {
  color: #f72a2a !important;
  font-size: 14px !important;
  padding: 5px 0;
}
</style>