<template>
  <div class="account">
    <div class="account__personal">
      <div class="account__personal_dashboard">
        <div class="account__personal_dashboard-nav">
          <NavMenu class="account__personal_dashboard-nav-menu"/>
        </div>
      </div>

      <div
          class="account__personal_info container"
          v-if="$route.path === '/personal'"
      >
        <div class="account__personal_info-blocks">
          <div class="account__personal_info-blocks-users green-block">
            <p class="semi-bold-txt">{{ $t('personal.account') }}</p>
            <div class="account__personal_info-blocks-users-ciphers">
              <p class="semi-bold-txt">{{ balance }} тг</p>
            </div>
          </div>

          <div class="account__personal_info-blocks-users gray-block">
            <p class="semi-bold-txt">{{ $t('personal.newUsers') }}</p>
            <div class="account__personal_info-blocks-users-ciphers">
              <p class="semi-bold-txt">{{ childrenCount }}</p>
            </div>
          </div>

          <div class="account__personal_info-blocks-users green-block">
            <p class="semi-bold-txt">{{ $t('personal.referrals') }}</p>
            <div class="account__personal_info-blocks-users-ciphers">
              <p class="semi-bold-txt">{{ newChildrenCount }}</p>
            </div>
          </div>
        </div>

        <div class="account__personal_info-replenishment green-block">
          <div
              class="account__personal_info-replenishment__instruction"
          >
            <div class="account__personal_info-replenishment__instruction-txt">
              <p class="account__personal_info-replenishment__instruction-txt semi-bold-txt" v-html="instruction"></p>
            </div>
            <div class="account__personal_info-replenishment__instruction-qr">
              <img :src="qr" alt="qrcode">
            </div>
          </div>
        </div>
        <div class="account__personal_info-tariffsD green-block">
          <table class="account__personal_info-tariffsD-history">
            <thead>
            <tr>
              <th>{{ $t('personal.tariffs') }}</th>
              <th>{{ $t('table.status') }}</th>
              <th>{{ $t('table.price') }}</th>
              <th>{{ $t('table.paid') }}</th>
              <th>{{ $t('table.endDate') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(tarrif, index) in tariffs" :key="index">
              <td class="medium-txt">{{ tarrif.title }}</td>
              <td class="medium-txt">{{ getStatusDesc(tarrif.status) }}</td>
              <td class="medium-txt">{{ tarrif.price }} тг</td>
              <td class="medium-txt">{{ tarrif.paid }} тг</td>
              <td class="medium-txt">{{ tarrif.end_date }}</td>
            </tr>
            </tbody>
          </table>
          <div class="account__personal_info-tariffsD-controls">
            <button
                @click="prevPage"
                class="main-button"
            >
              {{ $t('buttons.back') }}
            </button>
            <span class="medium-txt">{{ currentTariffsPage }}</span>
            <button
                @click="nextPage"
                class="main-button"
                :disabled="!hasNextPage"
            >
              {{ $t('buttons.next') }}
            </button>
          </div>
        </div>
        <div class="account__personal_info-tariffsM grey-block">
          <div
              v-for="(tarrif, index) in tariffs"
              :key="index"
          >
            <p class="medium-txt">{{ $t('table.endDate') }}: {{ tarrif.end_date }}</p>
            <p class="medium-txt">{{ $t('table.status') }}: {{getStatusDesc(tarrif.status) }}</p>
            <div class="account__personal_info-tariffsM-title green-block">
              <p class="semi-bold-txt">{{ $t('personal.tariffs') }}: {{ tarrif.title }}</p>
              <div class="account__personal_info-tariffsM-title-price">
                <p class="medium-txt">{{ $t('table.price') }}: {{ tarrif.price }} тг</p>
                <div class="account__personal_info-tariffsM-title-price-paid">
                  <p class="medium-txt">{{ $t('table.paid') }}: {{ tarrif.paid }} тг</p>
                </div>
              </div>
            </div>
          </div>
          <div class="account__personal_info-tariffsD-controls">
            <button
                @click="prevPage"
                class="main-button"
            >
              {{ $t('buttons.back') }}
            </button>
            <span class="medium-txt">{{ currentTariffsPage }}</span>
            <button
                @click="nextPage"
                class="main-button"
                :disabled="!hasNextPage"
            >
              {{ $t('buttons.next') }}
            </button>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavMenu from "@/components/NavigationMenu.vue";
import {mapActions, mapGetters} from "vuex";
export default {
  components: { NavMenu },
  data() {
    return {
      balance: 0,
      childrenCount: 0,
      newChildrenCount: 0,
      tariffs: [],
      qr: '',
      instruction: '',
      currentTariffsPage: 1,
      totalPageSize: 10,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.currentTariffsPage) {
      localStorage.removeItem('currentTariffsPage');
      next();
    }
  },
  computed: {
    ...mapGetters(['getAuth', 'getUser']),
    totalPages() {
      return Math.ceil(this.tariffs.length / this.currentTariffsPage);
    },
    hasNextPage() {
      return this.currentTariffsPage < this.totalPages;
    },
  },
  methods: {
    ...mapActions(["requestUser"]),
    async fetchTariffs() {
      try {
        const response = await this.$axios.get(`/V1/user/profile/tariffs`, {
          params: {
            page: localStorage.getItem('currentPage') || 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (response.data.data) {
          this.tariffs = response.data.data;
        }
      } catch (e) {
        this.$toaster.error(e.message);
      }
    },
    getStatusDesc(statusCode) {
      const statusMap = {
        0: this.$t('history.deadlineOver'),
        1: this.$t('history.active'),
        2: this.$t('history.earlyCompletion'),
      };
      return statusMap[statusCode] || '';
    },
    nextPage() {
      if (this.currentTariffsPage < this.totalPages) {
        this.currentTariffsPage += 1;
        this.saveTariffsPageToLocalStorage();
        this.fetchTariffs();
      }
    },
    prevPage() {
      if (this.currentTariffsPage > 1) {
        this.currentTariffsPage -= 1;
        this.saveTariffsPageToLocalStorage();
        this.fetchTariffs();
      }
    },
    saveTariffsPageToLocalStorage() {
      localStorage.setItem('currentTariffsPage', this.currentTariffsPage);
    },
    loadTariffsPageFromLocalStorage() {
      const storedPage = localStorage.getItem('currentTariffsPage');
      this.currentTariffsPage = storedPage ? parseInt(storedPage) : 1;
    },
  },
  async created() {
    await this.$axios.get(`/V1/user/profile/balance`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then((response) => {
          this.balance = response.data.balance;
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
    await this.$axios.get(`/V1/user/profile/children-count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then((response) => {
          this.childrenCount = response.data.children_count;
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
    await this.$axios.get(`/V1/user/profile/new-children-count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then((response) => {
          this.newChildrenCount = response.data.children_count;
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
    await this.$axios.get(`/V1/qr`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then((response) => {
          this.qr = response.data.qr;
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
    await this.$axios.get(`/V1/instruction-qr`,
        {
          params:{
            lang: localStorage.getItem('lang') || 'ru'
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then((response) => {
          this.instruction = response.data.instruction;
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
    await this.fetchTariffs();
    this.loadTariffsPageFromLocalStorage();
  }
}
</script>

<style lang="scss" scoped>
.account__personal {
  display: flex;
  color: $blackText;
  position: relative;
  gap: 20px;
  @media screen and (max-width: $tablet) {
    padding: 20px;
    flex-direction: column;
  }
  &_dashboard {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    min-width: 30%;
    @media screen and (max-width: $laptop) {
      min-width: 35%;
      background-color: transparent;
    }
    &-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    margin-top: 120px;
    @media screen and (max-width: $tablet) {
      margin-top: 30px;
    }
    &-replenishment {
      display: flex;
      align-items: baseline;
      padding: 40px;
      @media screen and (max-width: $tablet) {
        padding: 20px;
      }
      &__instruction {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 70px;
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          align-items: center;
        }
        &-txt {
          width: 100%;
          textarea {
            width: 100%;
            height: auto;
            background: transparent;
            border: none;
            display: inline-table;
            outline: none;
          }
        }
        &-qr {
          width: 40%;
          display: flex;
          flex-direction: column;
          gap: 40px;
          @media screen and (max-width: $tablet) {
            width: 100%;
          }
          input {
            border-radius: 10px;
            padding: 10px 20px;
          }
        }
      }
    }

    &-tariffsD {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 50px;
      gap: 50px;
      @media screen and (max-width: 1200px) {
        display: none;
      }
      &-history {
        color: #8C8C8C;
        width: 100%;
        border-spacing: 50px 25px;
        thead {
          width: 100%;
          th {
            padding-bottom: 25px;
            text-align: start;
          }
        }
      }
      &-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 15px;
        span {
          padding: 16px;
          border-radius: 5px;
          background-color: #EDEDED;
        }
      }
    }
    &-tariffsM {
      display: none;
      @media screen and (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        width: 100%;
        gap: 20px;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-title {
          display: flex;
          flex-direction: column;
          gap: 10px;
          &-price {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            p {
              font-size: 16px;
            }
            &-paid {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
    &-blocks {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
      &-users {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 33%;
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
        &-ciphers {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          p {
            font-size: 24px;
          }
          div {
            display: flex;
            align-items: center;
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>