<template>
  <div class="register">
    <div class="register__view">
      <div class="register__view-form white-block">
        <h1 class="register__view-form-title regular-txt">{{ $t('auth.register') }}</h1>
        <div class="register__view-form-inputs">
          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('auth.invitedPhoneInput') }}</p>
            <input
                type="text"
                v-model="v$.invitedPhone.$model"
                :placeholder="$t('placeholder.invitedPhone')"
                maxlength="18"
                v-imask="phoneMask"
                @input="checkInvitedPhone"
                @blur="isNumber"
                @accept="onAccept"
                @complete="onComplete"
            />
            <div class="register__view-form-inputs-required-loading" v-if="loading">
              <div class="spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <template v-if="invitedUserName && !loading">
              <div class="register__invitedName green-block">
                <p class="register__invitedName-text medium-txt">{{ invitedUserName }}</p>
              </div>
            </template>
            <template v-for="(error, index) of v$.invitedPhone.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('auth.phoneInput') }}</p>
            <input
                type="text"
                v-model="v$.phone.$model"
                :placeholder="$t('placeholder.phone')"
                maxlength="18"
                v-imask="phoneMask"
                @input="isNumber"
                @accept="onAccept"
                @complete="onComplete"
            />
            <template v-for="(error, index) of v$.phone.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('auth.fullName') }}</p>
            <input
                type="text"
                v-model="v$.fullName.$model"
                :placeholder="$t('placeholder.fullName')"
                @keyup="onlyText()"
            />
            <template v-for="(error, index) of v$.fullName.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">E-mail</p>
            <input
                type="text"
                v-model="v$.email.$model"
                :placeholder="$t('placeholder.email')"
            />
            <template v-for="(error, index) of v$.email.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('auth.iinInput') }}</p>
            <input
                type="text"
                v-model="v$.iin.$model"
                maxlength="12"
                :placeholder="$t('placeholder.iin')"
            />
            <template v-for="(error, index) of v$.iin.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('auth.birthdayInput') }}</p>
            <input
                type="date"
                v-model="v$.birthday.$model"
                :placeholder="$t('placeholder.birthday')"
            />
            <template v-for="(error, index) of v$.birthday.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('placeholder.gender') }}</p>
            <form class="register__view-form-inputs-required-gender">
              <div>
                <input type="radio" value="0" id="0" v-model="gender" name="male" required>
                <p class="regular-txt">{{ $t('placeholder.genderMale') }}</p>
              </div>
              <div>
                <input type="radio" value="1" id="1" v-model="gender" name="female" required>
                <p class="regular-txt">{{ $t('placeholder.genderFemale') }}</p>
              </div>
            </form>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('auth.passwordInput') }}</p>
            <input
                type="password"
                v-model="v$.password.$model"
                :placeholder="$t('placeholder.password')"
                @keyup="onlyLatin()"
            />
            <template v-if="v$.password.$error">
              <p class="errorValid" v-if="v$.password.required.$invalid" :key="index">{{$t('errors.required')}}</p>
              <p class="errorValid" v-if="v$.password.minLength.$invalid" :key="index">{{$t('errors.minLength', {value: 8})}}</p>
              <p class="errorValid" v-if="v$.password.containsUpperCase.$invalid" :key="index">{{$t('errors.containsUpperCase')}}</p>
              <p class="errorValid" v-if="v$.password.containsNumber.$invalid" :key="index">{{$t('errors.containsNumber')}}</p>
              <p class="errorValid" v-if="v$.password.noSpaces.$invalid" :key="index">{{$t('errors.noSpaces')}}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-required">
            <p class="regular-txt">{{ $t('auth.confirmPasswordInput') }}</p>
            <input
                type="password"
                v-model="v$.passwordConfirm.$model"
                :placeholder="$t('placeholder.passwordConfirm')"
            />
            <template v-for="(error, index) of v$.passwordConfirm.$errors" :key="index">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="register__view-form-inputs-button">
            <button
                class="register__view-form-inputs-button-enter main-button"
                @click="registerForm"
            >
              {{ $t('buttons.register') }}
            </button>
          </div>
        </div>
      </div>
      <div class="register__view-form-inputs-login">
        <p class="regular-txt">{{ $t('auth.existAcc') }} <span @click="$router.push('/')" >{{ $t('auth.login') }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { mask } from "vue-the-mask";
import { IMaskDirective } from "vue-imask";
import {
  required,
  minLength,
  email,
  helpers, sameAs
} from "@vuelidate/validators";

export default {
  components: {
  },
  directives: {
    imask: IMaskDirective,
    mask
  },
  data: () => ({
    v$: useVuelidate(),
    email: "",
    invitedPhone: "",
    phone: "",
    iin: "",
    birthday: "",
    fullName: "",
    password: "",
    passwordConfirm: "",
    gender: null,
    invitedUserName: "",
    loading: false,
    phoneMask: {
      mask: "+{7}-({000})-000-00-00",
      lazy: true,
    },
    operators: [
      700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
      761, 762, 763, 764, 771, 775, 776, 777, 778,
    ],
  }),
  validations() {
    return {
      fullName: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.fullName')), minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        email: helpers.withMessage((this.$t('errors.email')), email),
      },
      phone: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.phone')), minLength(18)),
      },
      invitedPhone: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.phone')), minLength(18)),
      },
      iin: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.iin')), minLength(12)),
      },
      birthday: {
        required: helpers.withMessage((this.$t('errors.required')), required),
      },
      gender: {
        required: helpers.withMessage((this.$t('errors.required')), required),
      },
      password: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage(
            this.$t('errors.minLength', {value: 8}),
            minLength(8)
        ),
        containsUpperCase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /\d/.test(value);
        },
        noSpaces(value) {
          return !/\s/.test(value);
        },
      },
      passwordConfirm: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        sameAs: helpers.withMessage((this.$t('errors.sameAs')), sameAs(this.password)
        ),
      }
    };
  },
  methods: {
    async registerForm() {
      if (!this.v$.$invalid && this.gender) {
        const data = {
          phone_invite: this.invitedPhone,
          phone: this.phone,
          name: this.fullName,
          email: this.email,
          birthday: this.birthday,
          gender: this.gender ? 0 : 1,
          iin: this.iin,
          password: this.password,
          password_confirmation: this.passwordConfirm,
        };

        this.$axios.post(`V1/user/register`, data)
            .then(res => {
              localStorage.setItem("access_token", res.data.access_token);
              this.$toaster.success(this.$t('toasters.successAuth'));
              this.resetForm();
              this.$router.push('/successfully');
            })
            .catch(err => {
              if(err.response.data.errors) {
                for (let field in err.response.data.errors) {
                  this.$toaster.error(err.response.data.errors[field][0]);
                }
              }
              if(err.response.data.error) {
                this.$toaster.error(err.response.data.error.message);
              }
            })
      }
      else {
        this.$toaster.error(this.$t('toasters.fullInput'));
      }
    },
    checkInvitedPhone() {
      if (this.invitedPhone.length === 18) {
        this.loading = true;
        this.phoneCheck();
      }
    },
    phoneCheck() {
      this.$axios.post(`V1/user/profile/user-name-email`, {
        phone: this.invitedPhone,
      })
          .then(response => {
            this.invitedUserName = response.data.data;
          })
          .catch(err => {
            this.$toaster.error(err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    resetForm() {
      this.email = "",
      this.password = "",
      this.passwordConfirm = "",
      this.invitedPhone = "",
      this.phone = "",
      this.fullName = "",
      this.birthday = "",
      this.iin = "",
      this.v$.$reset()
    },
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error(this.$t('toasters.phone'));
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail;
    },
    onlyLatin() {
      this.password = this.password.replace(/[^a-zA-Z0-9\s.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
    },
    onlyText() {
      this.fullName = this.fullName.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.fullName = this.fullName.replace(/\.{2,}|\s{2,}|-{2,}/g, function (match) {
        return match.substr(0, 1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    height: 100%;
    width: 100%;
    gap: 20px;
    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 60px 0;
      img{
        width: clamp(350px, calc(21.875rem + ((1vw - 10.24px) * 44.6429)), 750px);;
        height: clamp(500px, calc(31.25rem + ((1vw - 10.24px) * 45.6473)), 909px);;
      }
      @media screen and (max-width: $tablet) {
        display: none;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 100px;
      width: 50%;
      @media screen and (max-width: $laptopL) {
        padding: 125px 50px;
      }
      @media screen and (max-width: $tablet) {
        padding: 30px 50px;
        width: 95%;
      }
      &-title {
        width: 100%;
        text-align: center;
        font-size: 30px;
      }
      &-inputs {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 35px;
        padding: 50px 0;
        input {
          width: 100%;
          border-radius: 10px;
          border: 2px solid #e0e0e0;
          padding: 20px 30px 20px 30px;
        }
        &-required {
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 10px;
          &-loading {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px;
            width: 100%;
          }
          &-gender {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            @media screen and (max-width: $tablet) {
              flex-direction: column;
            }
            div {
              display: flex;
              align-items: center;
              gap: 10px;
            }
            input[type="radio"] {
              width: 12px;
              height: 12px;
              border: 2px solid white;
              border-radius: 50%;
              outline: none;
              transition: border-color 0.2s ease;
            }
          }
          p {
            font-size: 17px;
          }
        }
        &-forgot {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          color: #e0e0e0;
        }
        &-button {
          display: flex;
          justify-content: center;
          margin-top: 65px;
          button {
            width: 300px;
          }
        }
        &-login {
          @media screen and (max-width: $tablet) {
            display: flex;
            justify-content: center;
          }
          span {
            color: $secondColor;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.spinner {
  position: absolute;
  width: 6px;
  height: 6px;
}

.spinner div {
  position: absolute;
  width: 50%;
  height: 150%;
  background: #86d4df;
  transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
}

.spinner div:nth-child(1) {
  --delay: 0.1;
  --rotation: 36;
  --translation: 150;
}

.spinner div:nth-child(2) {
  --delay: 0.2;
  --rotation: 72;
  --translation: 150;
}

.spinner div:nth-child(3) {
  --delay: 0.3;
  --rotation: 108;
  --translation: 150;
}

.spinner div:nth-child(4) {
  --delay: 0.4;
  --rotation: 144;
  --translation: 150;
}

.spinner div:nth-child(5) {
  --delay: 0.5;
  --rotation: 180;
  --translation: 150;
}

.spinner div:nth-child(6) {
  --delay: 0.6;
  --rotation: 216;
  --translation: 150;
}

.spinner div:nth-child(7) {
  --delay: 0.7;
  --rotation: 252;
  --translation: 150;
}

.spinner div:nth-child(8) {
  --delay: 0.8;
  --rotation: 288;
  --translation: 150;
}

.spinner div:nth-child(9) {
  --delay: 0.9;
  --rotation: 324;
  --translation: 150;
}

.spinner div:nth-child(10) {
  --delay: 1;
  --rotation: 360;
  --translation: 150;
}

@keyframes spinner-fzua35 {
  0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
  }
}
.errorValid {
  color: #f72a2a !important;
  font-size: 14px !important;
  padding: 5px 0;
}
</style>