<template>
  <div class="wallet container">
    <div class="wallet__desktop">
      <div class="wallet__desktop-table">
        <h1 class="semi-bold-txt">{{ $t('history.replenishmentHistory') }}</h1>
        <h1 class="semi-bold-txt">{{ $t('personal.balance') }}: {{ balance }} тг</h1>
        <div class="wallet__desktop-table-search">
          <div class="wallet__desktop-table-search-flex">
            <p class="medium-txt">{{ $t('date') }}</p>
            <div class="wallet__desktop-table-search-flex-input">
              <p class="regular-txt">{{ $t('history.fromDate') }}</p>
              <div class="wallet__desktop-table-search-flex-input-container">
                <input
                    type="date"
                    v-model="fromDate"
                />
              </div>
            </div>
            <div class="wallet__desktop-table-search-flex-input">
              <p class="regular-txt">{{ $t('history.untilDate') }}</p>
              <div class="wallet__desktop-table-search-flex-input-container">
                <input
                    type="date"
                    v-model="toDate"
                />
              </div>
            </div>
            <div class="wallet__desktop-table-search-flex-input-btn">
              <button
                  class="main-button"
                  @click="findDate"
              >
                {{ $t('buttons.find') }}
              </button>
            </div>
          </div>

          <div class="wallet__desktop-table-search-reset">
            <span
                style="cursor: pointer;"
                class="wallet__desktop-table-search-reset-btn"
                @click="resetDate"
            >
              {{ $t('buttons.reset') }}
            </span>
          </div>
        </div>

        <table class="wallet__desktop-table-history">
          <thead>
          <tr>
            <th>{{ $t('date') }}</th>
            <th>{{ $t('table.status') }}</th>
            <th>{{ $t('table.outputAmount') }}</th>
            <th>{{ $t('table.inputAmount') }}</th>
            <th>{{ $t('table.comments') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(transaction, index) in transactions" :key="index">
            <td class="medium-txt">{{ transaction.date }}</td>
            <td class="medium-txt">{{ getStatusText(transaction.status) }}</td>
            <td class="output-balance medium-txt">{{ transaction.output_balance}} тг</td>
            <td class="input-balance medium-txt">{{ transaction.input_balance }} тг</td>
            <td class="medium-txt">{{ transaction.comment }}</td>
          </tr>
          </tbody>
        </table>
        <div class="wallet__desktop-table-controls">
          <button
              @click="prevPage"
              class="main-button"
          >
            {{ $t('buttons.back') }}
          </button>
          <span class="medium-txt">{{ currentPage }}</span>
          <button
              @click="nextPage"
              class="main-button"
              :disabled="!hasNextPage"
          >
            {{ $t('buttons.next') }}
          </button>
        </div>
      </div>
    </div>

    <div class="wallet__mobile">
      <div
          class="wallet__mobile-table"
          v-for="(transaction, index) in transactions"
          :key="index"
      >
        <p class="medium-txt">{{ transaction.date }}</p>
        <div class="wallet__mobile-table-transactions grey-block">
          <p class="medium-txt">{{ getStatusText(transaction.status) }}</p>
          <div class="wallet__mobile-table-transactions-input">
            <p class="semi-bold-txt">{{ $t('table.inputAmount') }}: <span class="input-balance light-txt">{{ transaction.input_balance }} тг</span></p>
            <div class="wallet__mobile-table-transactions-amount-output">
              <p class="semi-bold-txt">{{ $t('table.outputAmount') }}: <span class="output-balance light-txt">{{ transaction.output_balance }} тг</span></p>
            </div>
            <div class="wallet__mobile-table-transactions-amount-output">
              <p class="semi-bold-txt">{{ $t('table.comments') }}: <span class="light-txt">{{ transaction.comment }}</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="wallet__mobile-controls">
        <button
            @click="prevPage"
            class="main-button"
        >
          {{ $t('buttons.back') }}
        </button>
        <span class="medium-txt">{{ currentPage }}</span>
        <button
            @click="nextPage"
            class="main-button"
            :disabled="!hasNextPage"
        >
          {{ $t('buttons.next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      transactions: [],
      filteredTransactions: [],
      fromDate: null,
      toDate: null,
      balance: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },
  async created() {
    await this.$axios.get(`/V1/user/profile/balance`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
    )
        .then((response) => {
          this.balance = response.data.balance;
        })
        .catch((e) => {
          this.$toaster.error(e.message);
        });
    await this.fetchTransactions();
    this.loadPageFromLocalStorage();
  },
  beforeRouteLeave(to, from, next) {
    if (this.currentPage) {
      localStorage.removeItem('currentPage');
      next();
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.transactions.length / this.currentPage);
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    async fetchTransactions() {
      try {
        const response = await this.$axios.get(`/V1/user/profile/balances`, {
          params: {
            page: localStorage.getItem('currentPage') || 1,
            lang: localStorage.getItem('lang') || 'ru'
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (response.data && response.data.data) {
          const fromDateObj = new Date(this.fromDate);
          const toDateObj = new Date(this.toDate);
          this.transactions = response.data.data;
          this.filteredTransactions = this.transactions.filter(transaction => {
            const transactionDate = new Date(transaction.date);
            return transactionDate >= fromDateObj && transactionDate <= toDateObj;
          });
        }
      } catch (e) {
        this.$toaster.error(e.message);
      }
    },
    getStatusText(statusCode) {
      const statusMap = {
        0: 'Ожидание',
        1: 'Отклонен',
        2: 'Ввод',
        3: 'Вывод',
        4: 'Завершен',
      };
      return statusMap[statusCode] || 'Неизвестный статус';
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.savePageToLocalStorage();
        this.fetchTransactions();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.savePageToLocalStorage();
        this.fetchTransactions();
      }
    },
    savePageToLocalStorage() {
      localStorage.setItem('currentPage', this.currentPage);
    },
    loadPageFromLocalStorage() {
      const storedPage = localStorage.getItem('currentPage');
      this.currentPage = storedPage ? parseInt(storedPage) : 1;
    },
    async findDate() {
      try {
        const response = await this.$axios.get(`/V1/user/profile/balances`, {
          params: {
            page: localStorage.getItem('currentPage') || 1,
            lang: localStorage.getItem('lang') || 'ru',
            start_date: this.fromDate,
            end_date: this.toDate,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        this.transactions = response.data.data;
      } catch (e) {
        this.$toaster.error(e.message);
      }
    },
    resetDate() {
      this.fromDate = null;
      this.toDate = null;
      this.fetchTransactions();
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $blackText;
  margin-top: 150px;
  @media screen and (max-width: $tablet) {
    margin-top: 30px;
  }
  &__desktop {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $tablet) {
      display: none;
    }
    &-table {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 40px;
      gap: 50px;
      &-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        width: 100%;
        color: #8C8C8C;
        @media screen and (max-width: $laptop) {
          align-items: flex-end;
          flex-direction: column;
          gap: 25px;
        }
        &-flex {
          display: flex;
          align-items: center;
          gap: 20px;
          &-input {
            display: flex;
            align-items: center;
            gap: 15px;
            input {
              width: 130px;
              height: 30px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 5px;
              padding: 10px 5px;
            }
          }
        }
      }

      &-history {
        color: #8C8C8C;
        width: 100%;
        border-collapse: collapse;
        thead {
          width: 100%;
          th {
            padding: 25px 30px;
            text-align: start;
          }
        }
        tbody {
          width: 100%;
          border-radius: 5px;
          td {
            text-align: start;
            padding: 20px 30px;
            border-radius: 5px;
          }
          tr:nth-of-type(odd) {
            background-color: rgba(0,0,0,.05);
          }
          tr:nth-of-type(even) {
            background-color: white;
          }
        }
      }

      &-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 15px;
        span {
          padding: 16px;
          border-radius: 5px;
          background-color: #EDEDED;
        }
      }
    }
  }
  &__mobile {
    display: none;
    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      width: 100%;
      gap: 20px;
    }
    &-table {
      &-transactions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-input {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
          p {
            font-size: 16px;
          }
          &-output {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    &-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 15px;
      span {
        padding: 16px;
        border-radius: 5px;
        background-color: #EDEDED;
      }
    }
  }
}
.output-balance {
  color: #F72A2A;
}
.input-balance {
  color: #009F65;
}
</style>