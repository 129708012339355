<template>
  <div class="loginpage">
    <div class="loginpage__view">
      <div class="loginpage__view-form white-block">
        <h1 class="loginpage__view-form-title regular-txt">{{ $t('auth.login') }}</h1>
        <div class="loginpage__view-form-inputs">
          <div class="loginpage__view-form-inputs-required">
            <input
                type="text"
                v-model="v$.login.$model"
                :placeholder="$t('placeholder.login')"
            />
            <template v-for="(error) of v$.login.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>

          <div class="loginpage__view-form-inputs-required">
            <input
                type="password"
                v-model="v$.password.$model"
                :placeholder="$t('placeholder.password')"
                @keyup="onlyLatin()"
            />
            <template v-for="(error) of v$.password.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
<!--          <div class="loginpage__view-form-inputs-forgot">-->
<!--            <span-->
<!--                class="loginpage__view-form-inputs-forgot-pass light-txt"-->
<!--            >-->
<!--              Забыли пароль?-->
<!--            </span>-->
<!--          </div>-->

          <div class="loginpage__view-form-inputs-button">
            <button
                class="loginpage__view-form-inputs-button-enter main-button"
                @click="loginForm"
            >
              {{ $t('buttons.enter') }}
            </button>
          </div>
        </div>
      </div>
      <div class="loginpage__view-form-inputs-register">
        <p class="regular-txt">{{ $t('auth.noAcc') }} <span @click="$router.push('/registration')" >{{ $t('buttons.create') }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  helpers
} from "@vuelidate/validators";
import {mapActions} from "vuex";

export default {
  components: {
  },
  data: () => ({
    v$: useVuelidate(),
    login: "",
    password: "",
  }),
  validations() {
    return {
      login: {
        required: helpers.withMessage((this.$t('errors.required')), required),
      },
      password: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage(
            this.$t('errors.minLength', {value: 8}),
            minLength(8)
        ),
        containsUpperCase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /\d/.test(value);
        },
        noSpaces(value) {
          return !/\s/.test(value);
        },
      },
    };
  },
  methods: {
    ...mapActions(["requestUser"]),
    async loginForm() {
      this.$axios.post("V1/user/login", {
        email: this.login,
        password: this.password
      })
          .then(res => {
            localStorage.setItem("access_token", res.data.access_token)
            this.$toaster.success(this.$t('toasters.successLogin'));
            this.requestUser();
            this.$router.push('/personal');
          })
          .catch(err => {
            if(err.response.data.errors) {
              for (let field in err.response.data.errors) {
                this.$toaster.error(err.response.data.errors[field][0]);
              }
            }
            if(err.response.data.error) {
              this.$toaster.error(err.response.data.error.message);
            }
          })
    },
    resetForm() {
      this.login = "",
      this.password = "",
      this.v$.$reset()
    },
    onlyLatin() {
      this.password = this.password.replace(/[^a-zA-Z0-9\s.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.loginpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &__view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    height: 100%;
    width: 100%;
    gap: 20px;
    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 60px 0;
      img{
        width: clamp(350px, calc(21.875rem + ((1vw - 10.24px) * 44.6429)), 750px);;
        height: clamp(500px, calc(31.25rem + ((1vw - 10.24px) * 45.6473)), 909px);;
      }
      @media screen and (max-width: $tablet) {
        display: none;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 100px;
      width: 50%;
      @media screen and (max-width: $laptopL) {
        padding: 125px 50px;
      }
      @media screen and (max-width: $tablet) {
        padding: 30px 50px;
        width: 95%;
      }
      &-title {
        width: 100%;
        text-align: center;
        font-size: 30px;
      }
      &-inputs {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 35px;
        padding: 50px 0;
        input {
          width: 100%;
          border-radius: 10px;
          border: 2px solid #e0e0e0;
          padding: 20px 30px 20px 30px;
        }
        &-required {
          p {
            color: #7C838A;
            font-size: 24px;
          }
        }
        &-forgot {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          color: #7C838A;
        }
        &-button {
          display: flex;
          justify-content: center;
          margin-top: 65px;
          button {
            width: 300px;
          }
        }
        &-register {
          @media screen and (max-width: $tablet) {
            display: flex;
            justify-content: center;
          }
          span {
            color: $secondColor;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.errorValid {
  color: #f72a2a !important;
  font-size: 14px !important;
  padding: 5px 0;
}
</style>