<template>
  <div class="notfound">
    <div class="notfound_view white-block container">
      <h1 class="notfound_view-message semi-bold-txt">{{ $t('errorNotFound') }}</h1>
      <div class="notfound_view-img">
        <img src="@/assets/images/png/notfound.png" alt="notfound">
      </div>
      <buttton @click="$router.push('/personal')" class="notfound_view-btn main-button">{{ $t('buttons.onMainPage') }}</buttton>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.notfound {
  &_view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 60px;
    width: 100%;
    height: 100vh;
    &-img {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &-message {
      text-align: center;
      font-size: 30px;
    }
  }
}
</style>